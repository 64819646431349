<template>
  <div>
    <div class="mid_box">
      <div @click="$router.go(-1)">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/back.png"
          alt=""
        />
        <span style="margin-right: 15px">返回</span>
      </div>
      <el-button type="primary" class="edit" @click="toOpen"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <el-button type="primary" class="edit" @click="toAskQuestion"
        >新增提问<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <div class="toSave1" @click="confirmSave">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/sava.png"
          alt=""
        />
        <span>完成检查</span>
      </div>
      <div class="toSave1" v-if="isCheck" @click="toChange">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/sava.png"
          alt=""
        />
        <span>已检查</span>
      </div>
      <div class="toSave1" v-else @click="toChange">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/sava.png"
          alt=""
        />
        <span>未检查</span>
      </div>
      <span style="margin-top: 5px; margin-left: 10px" @click="delItem">
        <img src="../../assets/images/Examine_img/del1.png" alt="" />
      </span>
      <el-button
        @click="exportExcel(false)"
        style="margin-left: 10px"
        type="primary"
        class="edit"
        >打印检查表</el-button
      >
      <el-button
        @click="exportExcel(true)"
        style="margin-left: 10px"
        type="primary"
        class="edit"
        >导出检查表</el-button
      >
    </div>
    <el-table
      v-if="isCheck"
      :data="tableData1.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      highlight-current-row
      border
      @row-click="handleRowClick"
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
        border: '1px solid #DCDCDC',
      }"
      id="tableData"
    >
      <template slot="empty">
        <span v-if="riskItem.status == 2" style="color: #969799">已检查</span>
      </template>
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="riskName" label="风控流程" width="200">
      </el-table-column>
      <el-table-column prop="riskPoint" label="风险点"> </el-table-column>
      <el-table-column prop="riskResponse" label="风险应对措施">
      </el-table-column>
      <el-table-column
        prop="auditResult"
        label="是否符合要求  "
        align="center"
        width="300"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 4, '不适用')"
              :class="{ auditactive: scope.row.auditResult === 4 }"
            >
              不适用
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-else
      :data="tableData2.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      border
      @row-click="handleRowClick"
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      highlight-current-row
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      id="tableData"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="riskName" label="风控流程" width="200">
      </el-table-column>
      <el-table-column prop="riskPoint" label="风险点"> </el-table-column>
      <el-table-column prop="riskResponse" label="风险应对措施">
      </el-table-column>
      <el-table-column
        prop="auditResult"
        label="是否符合要求  "
        align="center"
        width="300"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <!-- //0未审核，1符合，2不符合，4不适用 -->
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 4, '不适用')"
              :class="{ auditactive: scope.row.auditResult === 4 }"
            >
              不适用
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="190"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt="" />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imagesCount > 0"
              style="margin-right: 50%"
              >{{ scope.row.imagesCount }}</span
            >
            <img src="../../assets/images/Examine_img/picture.png" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="isCheck ? tableData1.length : tableData2.length"
    >
    </el-pagination>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="company"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属部门:</span>
        <el-select
          v-model="deptID"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属岗位:</span>
        <el-select
          v-model="postID"
          placeholder="请选择"
          @change="gettitle"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 0.8%">抽取题目:&ensp;</span>
          <el-table
            :data="titlelist"
            @selection-change="selectionDraw"
            style="width: 100%"
            height="250"
          >
            <el-table-column
              prop="fileName"
              label="文件名称"
              width="150"
            ></el-table-column>
            <el-table-column prop="fileNo" label="文件编号"></el-table-column>
            <el-table-column
              prop="questionCount"
              label="总题数"
            ></el-table-column>
            <el-table-column type="selection" width="55"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增提问" :visible.sync="dialogVisible1" width="38%">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin: 20px; width: 60px">问题描述</div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="riskQuestion"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible1 = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%">
      <el-form label-width="80px" :inline="true">
        <el-form-item label="审核结果:">
          <span v-if="rowNum == 1">符合</span>
          <span v-if="rowNum == 2">不符合</span>
          <span v-if="rowNum == 4">不适用</span>
        </el-form-item>
      </el-form>
      <el-form label-width="80px">
        <el-form-item label="备注:">
          <el-input
            v-model="rowItem.remark"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传照片:">
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            :headers="token"
            :key="uploadKey"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            ></el-image>
            <i
              class="el-icon-delete-solid"
              v-show="index === Curindex"
              @click="delImg(item)"
            ></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
    >
      <div style="padding-bottom: 10px">上传图片</div>
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
        :key="uploadKey"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div style="padding: 10px 0">已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>
        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="error-dialog error-dialog2"
      :title="isExport ? '导出excel' : '打印excel'"
      :visible.sync="showExportExal"
      width="80%"
      top="5vh"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        height="63vh"
        border
        highlight-current-row
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        id="pdfDom"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="riskName" label="风控流程" width="200">
        </el-table-column>
        <el-table-column prop="riskPoint" label="风险点"> </el-table-column>
        <el-table-column prop="riskResponse" label="风险应对措施">
        </el-table-column>
        <el-table-column
          prop="auditResult"
          label="是否符合要求  "
          align="center"
          width="300"
        >
          <template slot-scope="scope">
            <div class="auditResult_box">
              <!-- //0未审核，1符合，2不符合，4不适用 -->
              <div>
                <span style="color: #5a8bff" v-if="scope.row.auditResult === 1"
                  >【符合】</span
                >
                <span v-else>符合</span>
              </div>
              <div>
                <span style="color: #5a8bff" v-if="scope.row.auditResult === 2"
                  >【不符合】</span
                >
                <span v-else>不符合</span>
              </div>
              <div>
                <span style="color: #5a8bff" v-if="scope.row.auditResult === 4"
                  >【不适用】</span
                >
                <span v-else>不适用</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="190"
        >
          <template slot-scope="scope">
            <div class="img_box">
              <img src="../../assets/images/Examine_img/add.png" alt="" />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span
              >
              <img src="../../assets/images/Examine_img/picture.png" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExportExal = false">取消</el-button>
        <el-button
          type="primary"
          @click="handleExportExal"
          v-loading="sureExportExal"
          >{{ isExport ? '确认导出' : '确认打印' }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod, baseURL } from '../../api/http'
import { compressImage } from '../../modules/CompressImage'
import imgTypeChange from '../../modules/imgChange'
import { exportExcel } from '../../modules/ExportExcel'
export default {
  data() {
    return {
      riskItem: {},
      elementHeight: 0,
      isCheck: true,
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableRowIndex: null,
      currentPage: '',
      curSize: 10,
      start: 0,
      end: 10,
      // 抽题
      options: [],
      option1: [],
      option2: [],
      company: '',
      deptID: '',
      postID: '',
      dialogVisible: false,
      titlelist: [],
      selectDrawVlue: [],
      //审核
      rowNum: 0,
      rowItem: {},
      dialogVisibleresult: false,
      // 图片
      BUrl: baseURL[process.env.NODE_ENV + ''],
      dialogVisibleimg: false,
      imgList: [],
      srcList: [],
      Curindex: null,
      uploadKey: 1,
      // 新增提问
      riskQuestion: '',
      dialogVisible1: false,
      // 打印导出
      showExportExal: false,
      htmlTitle: '控风审核',
      sureExportExal: false,
      isExport: true,
    }
  },
  mounted() {
    this.riskItem = JSON.parse(this.$route.query.riskItem)
    this.getElementHeight()
    this.getTableData()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('tableData').offsetTop + 60 + 52)
      })
    },
    // 对表格的操作
    handleRowClick(row) {
      this.tableRowIndex = row.index
      this.rowItem = row
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    toChange() {
      this.isCheck = !this.isCheck
    },
    getTableData() {
      get(
        '/api/RiskAuditList/GetByRiskAuditId?RiskAuditId=' + this.riskItem.id
      ).then((res) => {
        if (res.code == 200) {
          if (res.data.length == 0) return
          this.tableData = res.data
          this.tableData1 = this.tableData.filter((item) => {
            return item.auditResult == 0
          })
          this.tableData2 = this.tableData.filter((item) => {
            return item.auditResult != 0
          })
        }
      })
    },
    // 抽题
    getCompanyList() {
      get('/api/Company/GetAllNoPs').then((resp) => {
        if (resp.code == 200) {
          this.options = resp.data
          this.company = Number(this.riskItem.companyId)
          this.getDeptList()
        }
      })
    },
    getDeptList() {
      get('/api/Department/GetNoTree?CompId=' + this.company).then((res) => {
        if (res.code == 200) {
          this.option1 = res.data
          this.deptID = Number(this.riskItem.deptId)
          if (Number(this.riskItem.postId) != 0) {
            this.getPostList()
          } else {
            this.gettitle()
          }
        }
      })
    },
    getPostList() {
      get('/api/Post?CompId=' + this.company + '&DeptId=' + this.deptID).then(
        (res) => {
          if (res.code == 200) {
            this.option2 = res.data
            this.postID = Number(this.riskItem.postId)
            this.gettitle()
          }
        }
      )
    },
    toOpen() {
      this.dialogVisible = true
      this.getCompanyList()
    },
    selectionDraw(e) {
      this.selectDrawVlue = e
    },
    gettitle() {
      this.titlelist = []
      get(
        '/api/RiskAudit/GetTitleList?CompId=' +
          this.company +
          '&DeptId=' +
          this.deptID +
          '&PostId=' +
          (this.postID ? this.postID : 0) +
          '&FileAuditId=0'
      ).then((resp) => {
        if (resp.code == 200) {
          this.titlelist = resp.data
        }
      })
    },
    toReAdd() {
      if (this.selectDrawVlue.length == 0) {
        this.$message({
          type: 'success',
          message: '未选择题目',
        })
        return
      }
      var url1 = '/api/RiskAudit/InsertLater' //未抽题（确定抽题接口必选抽题题目，该接口目前用不到）
      var url2 = '/api/RiskAudit/InsertAgain' //重新抽题
      var url = this.tableData.length == 0 ? url1 : url2
      post(
        url +
          '?RiskAuditId=' +
          this.riskItem.id +
          '&CompId=' +
          this.company +
          '&DeptId=' +
          this.deptID +
          '&PostId=' +
          (this.postID ? this.postID : 0),
        this.selectDrawVlue
      ).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: 'warning',
            message: '抽题成功',
          })
          this.dialogVisible = false
          this.getTableData()
        } else {
          this.$message.error(resp.message)
        }
      })
    },
    // 新增提问
    toAskQuestion() {
      this.dialogVisible1 = true
      this.riskQuestion = ''
    },
    toAddASK() {
      var data = {
        id: 0,
        riskAuditId: this.riskItem.id,
        questionId: 0,
        riskName: this.riskQuestion,
        riskPoint: '',
        riskResponse: '',
        unqualityType: '',
        violatedTerms: '',
        remark: '',
        auditResult: 0,
        imagesCount: 0,
        isDelete: 0,
      }
      post('/api/RiskAuditList?AuditType=风控审核', data).then((resp) => {
        this.dialogVisible1 = false
        if (resp.code == 200) {
          this.$message({
            type: 'success',
            message: '提问成功',
          })
          this.getTableData()
        } else {
          this.$message.error(resp.message)
        }
      })
    },
    // 审核
    changeResult(row, num) {
      this.rowItem = row
      this.rowNum = num
      if (num != 1) {
        this.dialogVisibleresult = true
        this.getimg()
        return
      }
      this.toSure()
    },
    toSure() {
      var data = {
        id: this.rowItem.id,
        riskAuditId: this.riskItem.id,
        questionId: this.rowItem.questionId,
        riskName: this.rowItem.riskName,
        riskPoint: this.rowItem.riskPoint,
        riskResponse: this.rowItem.riskResponse,
        unqualityType: this.rowItem.unqualityType,
        violatedTerms: this.rowItem.violatedTerms,
        remark: this.rowItem.remark,
        auditResult: this.rowNum,
        imagesCount: this.rowItem.imagesCount,
        isDelete: 0,
      }
      put('/api/RiskAuditList?AuditType=风控审核', data).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: 'success',
            message: resp.code == 200 ? '保存成功' : resp.message,
          })
          this.dialogVisibleresult = false
          this.tableRowIndex = null
          this.getTableData()
        } else {
          this.$message.error(resp.message)
        }
      })
    },
    // 图片
    openImg(e) {
      this.rowItem = e
      this.dialogVisibleimg = true
      this.getimg()
    },
    toPostImg() {
      this.dialogVisibleimg = false
      this.$refs.upload.uploadFiles = []
      this.uploadKey++
    },
    overimg(index) {
      this.Curindex = index
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file)
        let newfile = await compressImage(file)
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile)
        }
        this.uploadFileReq(newfile)
      }
    },
    uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          if (res.code == 200) {
            var data = {
              RiskAuditListtId: this.rowItem.id,
              ImageRemark: '',
              ImageAddress: this.BUrl + '/uploads/' + res.value,
              saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id,
            }
            post('/api/RiskAuditListImage', {
              data: JSON.stringify([data]),
            }).then((resp) => {
              if (resp.code == 200) {
                this.$refs.upload.uploadFiles = []
                this.uploadKey++
                this.getimg()
              } else {
                this.$message.error(resp.message)
              }
            })
          } else {
            this.uploadKey++
            this.$refs.upload.uploadFiles = []
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$refs.upload.uploadFiles = []
          this.uploadKey++
          this.$message.error('上传失败')
        })
    },
    getimg() {
      if (this.srcList.length != 0) {
        this.srcList = []
        this.$refs.upload.uploadFiles = []
        this.uploadKey++
      }
      get('/api/RiskAuditListImage/' + this.rowItem.id).then((res) => {
        if (res.code == 200) {
          this.imgList = res.value
          if (res.value == null) return
          res.value.forEach((element) => {
            this.srcList.push(element.picNameAndAddr)
          })
          this.rowItem.imagesCount = this.srcList.length
        }
      })
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr == item) {
          this.$confirm('此操作将永久删除该图片', '是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              deleteMethod('/api/RiskAuditListImage?Id=' + e.id).then(() => {
                this.getimg()
              })
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              })
            })
        }
      })
    },
    // 删除
    delItem() {
      if (this.riskItem.status == 2) {
        this.$message({
          type: 'success',
          message: '审核完成，不允许删除！',
        })
        return
      }
      if (this.tableRowIndex == null) {
        this.$message({
          type: 'info',
          message: '未选择题目',
        })
        return
      }
      if (this.rowItem.questionId != 0) {
        this.$message({
          type: 'info',
          message: '非自定义题目不可删除',
        })
        return
      }
      this.$confirm('此操作将永久删除该题目', '是否继续?', '提示')
        .then(() => {
          deleteMethod('/api/RiskAuditList?Id=' + this.rowItem.id)
            .then((res) => {
              if (res.code == 200) {
                this.getTableData()
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
              } else {
                this.$message.error(res.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    // 完成检查
    confirmSave() {
      var isSave = true
      this.tableData.forEach((e) => {
        if (e.auditResult == 0) isSave = false
      })
      if (!isSave) {
        this.$message({
          type: 'success',
          message: '未完成审核',
        })
        return
      }
      if (this.riskItem.status == 2) {
        this.$message({
          type: 'success',
          message: '已经审核完成，请勿重复完成',
        })
        return
      }
      this.riskItem.status = 2
      this.riskItem.completeTime = new Date().toISOString()
      this.riskItem.saveTime = new Date().toISOString()
      put('/api/RiskAudit', this.riskItem)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '检查成功',
            })
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('检查失败')
        })
    },
    // 导出
    exportExcel(isExport) {
      this.isExport = isExport
      this.showExportExal = true
    },
    handleExportExal() {
      if (this.isExport) {
        this.sureExportExal = true
        var isOk = null
        isOk = exportExcel('#pdfDom', '风控审核.xlsx')
        if (isOk != null) {
          this.sureExportExal = false
        }
      } else {
        this.getPdf()
      }
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
}
</script>

<style scoped>
@import '../../assets/style/Examine_CSS.css';
</style>